import Header from './components/Header'
import Footer from './components/Footer'
import { Link } from 'react-router-dom'
import pashumitraApp from './images/website-graphics/advertise-app.png';
import advertisement from './images/website-graphics/advertise-app.png'
import advertisementDimensions from './images/website-graphics/advertise-dimen.png'
import StickySection from './components/StickySection'
import AppFeatures from './components/AppFeatures';

function App() {
  return (
      <>
        <Header/>
        <section className='hero'>
          <div className="pt-5 mt-3 text-left container">
            <div className="row">
              <div className="col-lg-6">
                <h1 className="display-1 fw-semibold lh-1 text-slate-950">We Are Pashumitra</h1>
                <p className="mt-3 pb-2 fs-5 text-slate-700">Pashumitra app developed for Farmers, Livestock Owner, Pet Owner, Dairy Farms, Sheep & Goat Farms, Pig Farms, Stud Farms and Veterinary and Farm Consultant</p>
                <div className="d-grid gap-2 d-sm-flex justify-content">
                  <Link to="/contact" className="btn btn-primary btn-logo px-5 py-3 me-sm-3 rounded-pill">Contact Us</Link>
                  <Link to="/about" className="btn btn-outline-primary btn-logo-outline-primary px-5 py-3 rounded-pill">About Us</Link>
                </div>
              </div>
              
              <div className="col-lg-6">
                <div className="pashumitra-app-hero text-center">
                    <img src={pashumitraApp} alt="Pashumitra App" className="pashumitra-app-hero img-fluid" loading="lazy"/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <StickySection/>

        <section className='advertisement'>
          <div className="container py-20">
            <div className="row">
              <div className="col-lg-5">
                <div className='h-100 d-flex flex-column justify-center align-items-start'>
                  <h2 className="display-2 fw-semibold text-slate-950">Advertise</h2>
                  <p className="pb-7 fs-5 text-slate-600 ">Aut qui hic atque tenetur quis eius quos, accusantium soluta minus veniam tempora deserunt?</p>
                  <Link to="/contact" className="d-inline-block rounded-pill btn btn-logo px-5 py-3 fw-medium text-white">Apply For Advertising</Link>
                </div>
              </div>
              <div className="col-lg-3">
                <img src={advertisement} alt="Advertisement" className='advertisement-appimg' loading="lazy"/>
              </div>
              <div className="col-lg-4">
                <div className='adver-stru'>
                  <img src={advertisementDimensions} alt="Advertisement Dimensions" className='img-fluid' loading="lazy"/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer/>
      </>
  );
}

export default App;
