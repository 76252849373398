import Header from '../components/Header'
import Footer from '../components/Footer'
import scan from '../images/scan.jpeg'
// import advertise from '../images/website-graphics/advertise-app.png'
import advertise from '../images/website-graphics/download-page.png'

import logo from '../images/pashumitra-logo.svg'
import googlePlay from '../images/google-play.svg'
import appStore from '../images/app-store.svg'
import { Link } from 'react-router-dom'

function Download() {
  return (
    <>
    <Header/>
    
    <section className='downloadapp bg-blue-100 pt-32 text-logo-50'>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h1 className='display-3 fw-semibold mb-5 text-slate-800'>Download &#34;Pashumitra&#34; App</h1>
                </div>
                {/* <div className="col-lg-4 text-center">
                    <div className='scanqr'>
                      <h2 className='text-3xl mt-24 capitalize text-slate-800'>Just SCAN the QR code</h2>
                      <img src={scan} alt='scan' className='w-50'/>
                    </div>
                </div> */}
                <div className="col-lg-4 text-center">
                    <div className='google-apple'>
                      <h2 className='text-3xl mt-24 capitalize text-slate-800'>Install App Form</h2>
                      <div className=''>
                          <Link to="/" className='d-block'>
                              <img src={googlePlay} alt='Google Play' className='w-50 my-2 rounded'/>
                          </Link>
                          
                          <div className='scanqr'>
                            <h2 className='fs-6 text-slate-800'>Just SCAN the QR code</h2>
                            <img src={scan} alt='scan' className='w-50'/>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className='appdemo'>
                        <img src={advertise} alt='logo' className='img-fluid w-100'/>
                    </div>
                </div>
                
                <div className="col-lg-4 text-center">
                    <div className='google-apple'>
                      <h2 className='text-3xl mt-24 capitalize text-slate-800'>Install App Form</h2>
                      <div className=''>
                          {/* <Link to="/" className='d-block'>
                              <img src={googlePlay} alt='Google Play' className='w-50 my-2 rounded'/>
                          </Link> */}
                          <Link to="/" className='d-block'>
                              <img src={appStore} alt='App Store' className='w-50 rounded'/>
                          </Link>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Footer/> 
    </>
  )
}

export default Download