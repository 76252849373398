import { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';

function CsrActivities() {
  const [csrActivities, setCsrActivities] = useState([]);

  useEffect(() => {
    // Fetch data from the API endpoint using axios
    axios.get('https://pashumitra.com/v1/general/get-csractivities')
      .then(response => {
        setCsrActivities(response.data.data.results);
        console.log('Data received from API:', response.data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className='csractivitiespage'>
      <Header/>
      <section className="container pt-5">
        <div className="row">
          <div className="col-lg-10">
            <h1 className='display-2 fw-semibold text-slate-950 mb-3'><span className='d-block fs-5 text-uppercase text-logo'>CSR Activities</span> Upcoming Events</h1>
          </div>
        </div>
      </section>

      <section className='csractivities bg-blue-100 pt-3'>
        <div className="container">
          <div className="row">
            {csrActivities.map(activity => (
              <div className="col-lg-3" key={activity.id}>
                <div className="card mb-3 border border-light-subtle">
                  {activity.image_name ? (
                      <img src={activity.image_name} alt={activity.title} className="card-img-top img-fluid" />
                    ) : (
                      <svg className="bd-placeholder-img card-img-top" width="100%" height="180" role="img" preserveAspectRatio="xMidYMid slice" focusable="false">
                        <title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#868e96"/>
                        <text x="38%" y="50%" fill="#dee2e6">No Image</text>
                      </svg>
                  )}
                  <div className="card-body">
                    <span class="badge bg-success-subtle text-success-emphasis rounded-pill py-2 px-3 ">{activity.schedule_date}</span>
                    <h5 className="fs-5 mt-2 fw-semibold text-slate-800">{activity.title}</h5>
                    <p className="card-text fs-6 d-flex flex-row lh-1 text-slate-600 mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#3b5c9b" class="me-2" viewBox="0 0 16 16"><path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/></svg>{activity.address}</p>
                    <Link className='btn-link small text-logo rounded-pill' to={`/csr-activity/${activity.id}`}>View Details</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer/>
    </div>
  );
}

export default CsrActivities;
