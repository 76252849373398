import Header from '../components/Header'
import Footer from '../components/Footer'
import founderceo from '../images/pashumitra-founder-and-ceo.jpeg'

function About() {
  return (
    <div className='aboutpage'>
      <Header/>
        <section className="container pt-5">
          <div className="row">
              <div className="col-lg-10">
                  <h1 className='display-2 fw-semibold text-slate-950 mb-3'><span className='d-block fs-5 text-uppercase text-logo'>About us</span>Who are Pashumitra!</h1>
              </div>
              <div className="col-lg-12">
                  <p className='fs-5'><span className='text-logo'>Pashumitra Enterprises</span> is bringing the approach of considering the interest of the Livestock Owner and implementing various activities for it so that the Livestock Owner does not suffer and they get maximum benefit by providing support and guidance in the animal husbandry business. At present, it is carrying out an ambitious plan to implement a pilot project in Maharashtra and to implement it all over India and later globally.</p>
              </div>
          </div>
        </section>

        {/* Chairman talks */}
        <section className='chairman-talks'>
          <div className="container bg-slate-100 rounded-5">
              <div className="row">
                  <div className="col-lg-3">
                    <div className='founderimg'>
                      <img src={founderceo} alt=' Pashumitra founder and CEO' className='img-fluid'/>
                    </div>
                  </div>
                  <div className="h-full flex flex-col justify-center items-start col-lg-9 py-5 mt-3">
                      <p className="fs-5">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>
                      <blockquote className="blockquote">
                        <p className="fw-semibold fs-4">Pramod Mahajan</p>
                        <footer className="blockquote-footer fs-6">Group Chairman &amp; <cite title="Source Title">Chief Executive Officer</cite></footer>
                      </blockquote>
                  </div>
              </div>
          </div>
        </section>

        <section className="py-5 mb-3">
          <div className="container">
            <div className='row'>
              <h2 className="display-3 fw-semibold text-slate-800 mb-4 text-center">Countless Success Stories</h2>
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <div className="row justify-content-center">
                  <div className="col">
                    <div className="text-center">
                      <h3 className="fs-5 fw-semibold mb-0 text-slate-800">Total Sales</h3>
                      <p className="display-5 fw-semibold  text-logo">$4.8m+</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-center">
                      <h3 className="fs-5 fw-semibold mb-0 text-slate-800">Official Addons</h3>
                      <p className="display-5 fw-semibold  text-logo">24+</p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="text-center">
                      <h3 className="fs-5 fw-semibold mb-0 text-slate-800">Total Addons</h3>
                      <p className="display-5 fw-semibold  text-logo">86+</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        
      <Footer/>
    </div>
  )
}

export default About