// import { useState, useEffect } from 'react';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import { Link, useParams } from 'react-router-dom'; // Import useParams hook

// function CsrActivityDetail() {
//   const { id } = useParams(); // Use useParams hook to get route parameters
//   const [csrActivityDetail, setCsrActivityDetail] = useState(null);

//   useEffect(() => {
//     // Fetch data from the API endpoint
//     fetch(`https://pashumitra.com/v1/general/get-csractivity-detail?detail_id=${id}`)
//       .then(response => response.json())
//       .then(data => {
//         setCsrActivityDetail(data.data.results);
//         console.log('Detail page data received from API:', data);
//       })
//       .catch(error => console.error('Error fetching detail page data:', error));
//   }, [id]); // Include id in dependency array to re-fetch data when id changes

//   return (
//     <div className='csractivitydetailpage'>
//       <Header/>
//       <section className="container pt-5">
//         <div className="row">
//           <div className="col-lg-10">
//             <h1 className='display-2 fw-semibold text-slate-950 mb-3'>CSR Activity Detail</h1>
//           </div>
//         </div>
//       </section>

//       <section className='csractivitydetail bg-blue-100 pt-32 border-bottom'>
//         <div className="container">
//           {csrActivityDetail ? (
//             <div className="card mb-3">
//               <img
//                 src={`${csrActivityDetail.image_base_path}/${csrActivityDetail.image_name}`}
//                 className="card-img-top"
//                 alt="Activity"
//               />
//               <div className="card-body">
//                 <h5 className="card-title">{csrActivityDetail.title}</h5>
//                 <p className="card-text">{csrActivityDetail.description}</p>
//                 {/* Add more details as needed */}
//               </div>
//             </div>
//           ) : (
//             <div>Loading...</div>
//           )}
//         </div>
//       </section>

//       <Footer/>
//     </div>
//   );
// }

// export default CsrActivityDetail;


import { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';

function CsrActivityDetail() {
  const { id } = useParams();
  const [csrActivityDetail, setCsrActivityDetail] = useState(null);

  useEffect(() => {
    // Fetch data from the API endpoint
    fetch(`https://pashumitra.com/v1/general/get-csractivity-detail?detail_id=${id}`)
      .then(response => response.json())
      .then(data => {
        setCsrActivityDetail(data.data);
        console.log('Detail page data received from API:', data);
      })
      .catch(error => console.error('Error fetching detail page data:', error));
  }, [id]);

  return (
    <div className='csractivitydetailpage'>
      <Header/>
      {/* <section className="container pt-5">
        <div className="row">
          <div className="col-lg-10">
            <h1 className='display-2 fw-semibold text-slate-950 mb-3'>CSR Activity Detail</h1>
          </div>
        </div>
      </section> */}

      <section className='csractivitydetail bg-blue-100 pt-5 border-bottom'>
        <div className="container">
          {csrActivityDetail ? (
            <div className="card mb-5 border-0 p-0">
              {/* {csrActivityDetail.module_images && csrActivityDetail.module_images.length > 0 && (
                <img src={`${csrActivityDetail.image_base_path}${csrActivityDetail.module_images[0].image_name}`} alt="CSR Activity Image" className="card-img-top" />
              )} */}

              <div className='col-lg-12'>
                <span class="badge bg-success-subtle text-success-emphasis rounded-pill py-2 px-3">{csrActivityDetail.results.schedule_date}</span>
                <h1 className='display-6 fw-semibold text-slate-950 py-3'>{csrActivityDetail.results.title}</h1>
              </div>

              <div className='col-lg-6'>
                {csrActivityDetail.module_images && csrActivityDetail.module_images.length > 0 ? (
                  <img 
                    src={`${csrActivityDetail.image_base_path}${csrActivityDetail.module_images[0].image_name}`} 
                    alt={csrActivityDetail.results.title} className="card-img-top img-fluid" 
                    onError={(e) => {
                      e.target.style.display = 'none'; // Hide the failed image
                    }}
                    
                  />
                ) : (
                  <svg className="bd-placeholder-img card-img-top" width="100%" height="180" role="img" preserveAspectRatio="xMidYMid slice" focusable="false">
                    <title>Placeholder</title>
                    <rect width="100%" height="100%" fill="#868e96"/>
                    <text x="38%" y="50%" fill="#dee2e6">No Image</text>
                  </svg>
                )}
              </div>
              
              <div className="card-body p-0">
                <h5 className="card-title"></h5>
                <p className="fs-5 text-slate-700">{csrActivityDetail.results.description}</p>
                {/* Add more details as needed */}
                <hr class="border border-primary border-3 opacity-75 my-5"></hr>
                
              </div>

              
    {/* <h2 class="pb-2 border-bottom fs-4 text-salte-950">Venues Details</h2> */}

    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
      <div class="col d-flex align-items-start">
        {/* <svg class="bi text-body-secondary flex-shrink-0 me-3" width="1.75em" height="1.75em"><use xlink:href="#bootstrap"/></svg> */}
        <div>
          <h3 class="mb-2 fs-4 text-body-emphasis text-capitalize">{csrActivityDetail.results.city_town}, {csrActivityDetail.results.state}</h3>
          <p className="card-text fs-5 d-flex flex-row lh-1 text-slate-800 mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#3b5c9b" class="me-2" viewBox="0 0 16 16"><path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/></svg>{csrActivityDetail.results.address}</p>
        </div>
      </div>
    </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </section>

      <Footer/>
    </div>
  );
}

export default CsrActivityDetail;